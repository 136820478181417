<template>
  <div class="center">
<!--    <div class="header" :class="{ 'sticky': isSticky }">-->
<!--      <span class="logo"></span>-->
<!--    </div>-->

<!--    <div class="content">-->
    <div>
      <div class="iframe-container">
        <div v-if="isLoading" class="loading-overlay">
          <div class="loader"></div>
          <p style="font-size: 26px;">Loading...</p>
        </div>
        <iframe
            v-else
            class="iframe-content"
            :src="queryObject.channelUrl"
            frameborder="0"
            @load="handleIframeLoad"
            @error="handleIframeError"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>

import {orderStatus} from "@/api/api";
import config from "@/config";

export default {
  data() {
    return {
      count: 0,
      queryObject: {
        channelUrl: null
      },
      isLoading: true,
      isSticky: false, // 添加这一行来定义 isSticky 属性
    }
  },
  created() {

  },
  mounted() {
    this.queryOrderInfo() // 立即请求一次
    // 每秒请求一次
    this.interval = setInterval(() => {

      if (this.count < 5) {
        this.queryOrderInfo()
      }

      this.count++

    }, 1000); // 每秒请求一次
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.interval); // 在组件销毁前清除定时器
  },
  methods: {
    queryOrderInfo() {
      // 请求后台获取 获取个卡订单
      orderStatus(this.$route.params.id).then(res => {
        const orderStatus = res.orderStatus;
        if (orderStatus === 0 || orderStatus === 1) {
          this.queryObject = res
          // 使用 setTimeout 模拟异步加载 iframe
          setTimeout(() => {
            this.isLoading = false;
          }, 2000)

        } else if (orderStatus === 2) {
          this.$router.push({
            name: config.successPage
          })
        } else if (orderStatus === 3 || orderStatus === 6) {
          this.$router.push({
            name: config.failPage
          })
        }
        // else {
        //   this.$router.push({
        //     name: config.errorPageRouteName,
        //     params: {errInfo: 'The order is expired, do not submit！please recharge!'}
        //   })
        // }
      })
      //     .catch(() => {
      //   this.$router.push({
      //     name: config.errorPageRouteName,
      //     params: {errInfo: 'The order is expired, do not submit,please reflash page！'}
      //   })
      // })
    },
    handleIframeLoad() {
      this.isLoading = false;
    },
    handleIframeError() {
      this.isLoading = false; // 忽略错误，继续隐藏加载状态
    },
    handleScroll() {
      this.isSticky = window.scrollY > 0;
    }
  }
}
</script>

<style lang="css" scoped>

.center {
  height: 100vh;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: top 0.3s ease;
}

.sticky {
  position: fixed;
}

.content {
  padding-top: 80px; /* 确保内容不被吸顶的 header 遮挡 */
}


.logo {
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: bold;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 100vh;
//padding-bottom: 56.25%; /* 16:9 aspect ratio (adjust as needed) */ overflow: hidden;
}

.iframe-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow-x: hidden;
}


.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>


